.t {
    border-top: 1px solid #b2c1ce
}

.b {
    border-bottom: 1px solid #b2c1ce
}

.l {
    border-left: 1px solid #b2c1ce
}

.r {
    border-right: 1px solid #b2c1ce
}
