body {
    font-family: 'Poppins', sans-serif;
    color: #676a81;
}

h1, h2, h3, h4, h5, h6 {
    color: #43485c;
}

h2 {
    text-transform: uppercase;
    font-weight: 600;
    font-size: 24px;
    padding-bottom: 50px;
    border-bottom: 1px solid #e5ebf2;
}

.element-section {
    padding: 50px 0 100px;
    border-bottom: 1px solid #e5ebf2;
}

.element-section > .container {
    margin-top: 5rem;
}