@property --myColor1 {
    syntax: '<color>';
    initial-value: #76ce33;
    inherits: false;
}

@property --myColor2 {
    syntax: '<color>';
    initial-value: #76ce33;
    inherits: false;
}

.sa-button {
    border: 0;
    border-radius: 2px;
    color: #fff;
    padding-left: 2rem;
    padding-right: 2rem;
    background: linear-gradient(to bottom right, var(--myColor1), var(--myColor2));
    transition: --myColor1 500ms, --myColor2 500ms;
}

.sa-button:hover {
    border: 0;
    --myColor1: #76ce33;
    --myColor2: #5ea529;
}

.read-more-link {
    font-weight: 400;
    display: inline-block;
    margin: 1rem 2rem 0 0;
    float: right;
    color: #76ce33;
    -webkit-transition: 0.3s linear;
    -moz-transition: 0.3s linear;
    -ms-transition: 0.3s linear;
    -o-transition: 0.3s linear;
    transition: 0.3s linear;
}

.read-more-link:hover {
    color: #5ea529;
}