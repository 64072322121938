.sa-nav-item {
    font-family: 'Poppins', sans-serif;
    font-weight: 450;
    padding: 2rem 1rem;
    margin: 0 0.4rem;
    float: left;
    display: inline-block;
    color: #676a81;
    text-transform: uppercase;
    -webkit-transition: 0.3s linear;
    -moz-transition: 0.3s linear;
    -ms-transition: 0.3s linear;
    -o-transition: 0.3s linear;
    transition: 0.3s linear;
}

.sa-nav-item:hover {
    color: #76ce33;
}

.sa-nav-item > a {
    text-decoration: inherit;
    color: inherit;
}
