.contact-form-element {
    width: 100%;
    padding: 1.2rem;
    font-size: 14px;
    border: 1px solid #fff;
}

.contact-form-element:focus {
    outline: none !important;
}

.contact-form-textarea {
    height: 15rem;
    resize: none;
    vertical-align: top;
}

.contact-icon {
    font-size: 1.5rem;
    color: #676a81;
    -webkit-transition: 0.3s linear;
    -moz-transition: 0.3s linear;
    -ms-transition: 0.3s linear;
    -o-transition: 0.3s linear;
    transition: 0.3s linear;
}

.contact-icon:hover {
    color: #76ce33;
}